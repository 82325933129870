import React from 'react';
import { TopologyItem } from '../../Topology/TopologyItem';
import { CcClusterType } from '../../../services/models/CcCluster';
import { getClusterTypeText } from '../../Clusters/ClusterTypeFormat';
import TopologySummary from './TopologySummary';
import { FormInstance } from 'antd/lib/form';
import ImportClusterDetails from './Import/ImportClusterDetails';
import NodeConfigurationSummary, {
    NodeConfigurationSummaryProps,
} from './NodeConfigurationSummary';
import { RepositoryItemKey } from '../../../common/DataEntry/RepositoryInput';
import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import CcBackup from '../../../services/models/CcBackup';
import { ServiceClusterWizardStep } from './ServiceClusterWizardStep';
import { WizardFormConfigurationStepProps } from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import ClusterNodesForm from './ClusterNodesForm';

export interface AddClusterNodeConfigFormValues {
    readonly?: boolean;
    repository?: RepositoryItemKey | string;
    sslEncryption?: boolean;
}

export interface ClusterConfiguratorFormValues {
    primaryCluster?: {
        clusterId?: number;
        address?: string;
        bidirectionalReplication?: boolean;
    };
    fromBackup?: {
        backup?: CcBackup;
    };
    details: {
        name?: string;
        tags?: string[];
        vendor?: string;
        version?: string;
        enterpriseToken?: string;
    };
    sshConfig: {
        sshUser?: string;
        sshKeyPath?: string;
        sshPort?: number;
        sshSudoPassword?: string;
        disableFirewall?: boolean;
        disableAppArmor?: boolean;
        installSoftware?: boolean;
    };
    nodeConfig: AddClusterNodeConfigFormValues;
    topology: TopologyItem[];
    topologyDataIps?: {
        [key: string]: string;
    };
}

export interface ClusterConfiguratorClusterType {
    name: string;
    key: CcClusterType;
    icon?: string | JSX.Element;
    showBackgroundIcon?: boolean;
    description: string | JSX.Element;
    documentationLink: string;
    disabled?: boolean;
}

export interface ClusterConfiguratorVendorVersion {
    name: string;
    value: string;
}

export interface ClusterConfiguratorVendor {
    name: string;
    value: string;
    extended?: boolean, // to allow selecting versions not only from predefined list
    versions: ClusterConfiguratorVendorVersion[];
    icon?: string;
}

export class ClusterConfigurator {
    public static getDefaults(): ClusterConfiguratorFormValues {
        return {
            details: {},
            sshConfig: {
                sshPort: 22,
                disableFirewall: true,
                disableAppArmor: true,
                installSoftware: true,
            },
            nodeConfig: {
                repository: RepositoryItemKey.USE_VENDOR,
            },
            topology: [],
            topologyDataIps: {},
        };
    }

    public static getJobData(formValues: ClusterConfiguratorFormValues): any {
        const {
            nodeConfig,
            sshConfig,
            details,
            primaryCluster,
            fromBackup,
        } = formValues;
        return {
            cluster_name: details.name,
            enable_ssl: nodeConfig.sslEncryption,
            with_tags: details.tags,
            ssh_user: sshConfig.sshUser,
            ssh_keyfile: sshConfig.sshKeyPath,
            ssh_port: sshConfig.sshPort,
            sudo_password:
                (sshConfig.sshUser !== 'root' && sshConfig.sshSudoPassword) ||
                '',
            type: details.vendor,
            vendor: details.vendor,
            version: details.version,
            generate_token: true,
            disable_firewall: sshConfig.disableFirewall,
            disable_selinux: sshConfig.disableAppArmor,
            deploy_agents: true,
            install_software: sshConfig.installSoftware,
            /// REPOSITORY

            ...(nodeConfig.repository === RepositoryItemKey.USE_VENDOR
                ? {}
                : nodeConfig.repository === RepositoryItemKey.CREATE_NEW
                ? { create_local_repository: true }
                : nodeConfig.repository === RepositoryItemKey.DO_NOT_USE_VENDOR
                ? { use_internal_repos: true }
                : { local_repository: nodeConfig.repository }),
            remote_cluster_id: primaryCluster?.clusterId,
            bidirectional_replication: primaryCluster?.bidirectionalReplication,
            master_address: primaryCluster?.address,
            backup_id: fromBackup?.backup?.getId(),
            read_only: primaryCluster?.clusterId
                ? nodeConfig.readonly
                : undefined,
        };
    }

    public static getJobOptions(
        formValues: ClusterConfiguratorFormValues
    ): any {
        const { primaryCluster } = formValues;
        return {
            job: {
                title: primaryCluster
                    ? `Create replica cluster`
                    : 'Create cluster',
            },
        };
    }

    public static getVendors(): ClusterConfiguratorVendor[] {
        return [
            {
                name: 'Default',
                value: 'default',
                versions: [{ name: 'Latest supported', value: 'latest' }],
            },
        ];
    }

    public static getVersions(vendor: string) {
        const vendorObject = this.getVendors().find((v) => v.value === vendor);
        if (vendorObject) {
            return vendorObject.versions;
        } else {
            return [];
        }
    }

    public static getImportDetailsStep(props: any): React.ReactNode {
        return <ImportClusterDetails {...props} />;
    }

    public static getNodeConfigurationStep(props: any): React.ReactNode {
        return null;
    }

    public static getNodeConfigurationValidate(
        form?: FormInstance
    ): ((() => void) | string[])[] {
        return [];
    }

    public static getTopologyStep(form: FormInstance): React.ReactNode {
        return <ClusterNodesForm form={form} />;
    }

    public static getTopologyValidate(
        form: FormInstance
    ): ((() => void) | string[] | string)[] {
        const topology: TopologyItem[] = form.getFieldValue('topology');
        return [
            () => {
                if (
                    topology.find(
                        (item) => item.status !== StatusFormatStatus.success
                    )
                ) {
                    throw new Error('All nodes should be reachable');
                }
                if (topology.length === 0) {
                    throw new Error('At least 1 node is needed');
                }
            },
        ];
    }

    public static getTopologySummary(form: FormInstance): React.ReactNode {
        return <TopologySummary form={form} />;
    }

    public static getNodeConfigurationSummary(
        props: NodeConfigurationSummaryProps
    ): React.ReactNode {
        return <NodeConfigurationSummary {...props} />;
    }

    public static getExtraConfigurationSummary(props: {
        form: FormInstance;
    }): React.ReactNode {
        return null;
    }

    public static getDeployClusterTypes(): ClusterConfiguratorClusterType[] {
        return [
            {
                name: getClusterTypeText(CcClusterType.TYPE_ELASTIC),
                key: CcClusterType.TYPE_ELASTIC,
                icon: 'img-logo-elastic.svg',
                description:
                    'Elasticsearch is a highly scalable open-source full-text search and analytics engine. It allows you to store, search, and analyze big volumes of data quickly and in near real time. It is generally used as the underlying engine/technology that powers applications that have complex search features and requirements.',
                documentationLink:
                    'https://www.elastic.co/guide/en/elasticsearch/reference/current/index.html',
            },
            {
                name: getClusterTypeText(CcClusterType.TYPE_REDIS),
                key: CcClusterType.TYPE_REDIS,
                icon: 'img-logo-redis.svg',
                description:
                    'Redis Sentinel is a high availability solution for Redis. Sentinels monitor Redis instances (primary and replicas), provides automatic failover and service discovery for clients.',
                documentationLink: 'https://redis.io/topics/sentinel',
            },
            {
                name: getClusterTypeText(CcClusterType.TYPE_MSSQL_SINGLE),
                key: CcClusterType.TYPE_MSSQL_SINGLE,
                icon: 'img-logo-mssql.svg',
                description:
                    'SQL Server is designed to solve challenges of the modern data professional including: Store enterprise data in a data lake and offer SQL and Spark query capability overall data. Reduce the need for Extract, Transform, and Load (ETL) applications by eliminating data movement.',
                documentationLink:
                    'https://docs.microsoft.com/en-us/sql/?view=sql-server-ver15',
            },
            {
                name: getClusterTypeText(CcClusterType.TYPE_REPLICATION),
                key: CcClusterType.TYPE_REPLICATION,
                description:
                    'MySQL is the world’s most popular open source database. Manage classic MySQL replication clusters with a primary and replicas.',
                documentationLink: 'https://dev.mysql.com/doc/',
            },
            {
                name: getClusterTypeText(CcClusterType.TYPE_GALERA),
                key: CcClusterType.TYPE_GALERA,
                description:
                    "Galera Cluster for MySQL is a true Multi-Master Cluster based on synchronous replication. It's an easy-to-use, high-availability solution, which provides high system up-time, no data loss and scalability for future growth.",
                documentationLink: 'https://galeracluster.com/',
            },
            {
                name: getClusterTypeText(CcClusterType.TYPE_POSTGRESQL),
                key: CcClusterType.TYPE_POSTGRESQL,
                icon: 'img-logo-postgresql.svg',
                description:
                    'PostgreSQL, also known as Postgres, is a free and open-source relational database management system emphasizing extensibility and SQL compliance. It was originally named POSTGRES, referring to its origins as a successor to the Ingres database developed at the University of California, Berkeley.',
                documentationLink: 'https://www.postgresql.org/docs/',
            },
            {
                name: getClusterTypeText(CcClusterType.TYPE_TIMESCALEDB),
                key: CcClusterType.TYPE_TIMESCALEDB,
                icon: 'img-logo-timescaledb.svg',
                description:
                    'TimescaleDB is the leading open-source relational database with support for time series and analytics. It expands PostgreSQL to handle relentless streams of time-series data with the performance, scalability, and usability that your application needs.',
                documentationLink: 'https://docs.timescale.com/',
            },
            {
                name: getClusterTypeText(CcClusterType.TYPE_MONGODB),
                key: CcClusterType.TYPE_MONGODB,
                description:
                    'MongoDB is a source-available cross-platform document-oriented database program. Classified as a NoSQL database program, MongoDB uses JSON-like documents with optional schemas. MongoDB is developed by MongoDB Inc. and licensed under the Server Side Public License.',
                documentationLink: 'https://docs.mongodb.com/',
            },
            {
                name: getClusterTypeText(CcClusterType.TYPE_MONGODB_SHARDS),
                key: CcClusterType.TYPE_MONGODB_SHARDS,
                description:
                    'Sharding is a method for distributing data across multiple machines. MongoDB uses sharding to support deployments with very large data sets and high throughput operations.',
                documentationLink:
                    'https://www.mongodb.com/docs/manual/sharding/',
            },
        ];
    }

    public static getDeploymentSteps(
        form: FormInstance,
        formValues?: ClusterConfiguratorFormValues
    ): (
        | ServiceClusterWizardStep
        | [ServiceClusterWizardStep | string, WizardFormConfigurationStepProps]
    )[] {
        return [
            ServiceClusterWizardStep.DETAILS,
            ServiceClusterWizardStep.SSH_CONFIG,
            ServiceClusterWizardStep.NODE_CONFIG,
            ServiceClusterWizardStep.TOPOLOGY,
            ServiceClusterWizardStep.PREVIEW,
        ];
    }

    public static getDeployClusterType(
        clusterType?: CcClusterType
    ): ClusterConfiguratorClusterType | undefined {
        return this.getDeployClusterTypes().find(
            (t: ClusterConfiguratorClusterType) => t.key === clusterType
        );
    }

    public static clusterDetailsExtra(form?: FormInstance): React.ReactNode {
        return null;
    }
}
