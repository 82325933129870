import CcBackupHostStorageLocation, {
    CcBackupHostStorageLocationProps,
} from './CcBackupHostStorageLocation';
import CcBackupCloudStorageLocation, {
    CcBackupCloudStorageLocationProps,
} from './CcBackupCloudStorageLocation';
import CcBackupMetadata, { CcBackupMetadataProps } from './CcBackupMetadata';
import { CcCloudProviderType } from './CcCloudCredentials';

export enum CcBackupStatus {
    STATUS_PENDING = 'Pending',
    STATUS_FAILED = 'Failed',
    STATUS_COMPLETED = 'Completed',
    STATUS_RUNNING = 'Running',
    // missing data statuses: https://github.com/severalnines/clustercontrol-enterprise/blob/master/src/cmonbackuprecord.cpp#L666
    STATUS_COMPLETED_MISSING_DATA = 'Completed (Missing data)',
    STATUS_FAILED_MISSING_DATA = 'Failed (Missing data)',
}

export enum CcBackupVerifiedStatus {
    VERIFIED = 'Verified',
    NOT_VERIFIED = 'NotVerified',
}

export type CcBackupConfigProps = {
    includeDatabases: [] | null;
    includeTables: [] | null;
    mysqldump_pitr_compat?: boolean;
    backupToIndividualFiles?: boolean;
};
export type CcBackupProps = {
    host_locations?: CcBackupHostStorageLocationProps[];
    cloud_locations?: CcBackupCloudStorageLocationProps[];
    metadata: CcBackupMetadataProps;
};

export enum CcBackupRetentionType {
    DEFAULT = 0,
    CUSTOM = 1,
    FOREVER = -1,
}

export enum CcBackupMethod {
    MYSQLDUMP = 'mysqldump',
    XTRABACKUP = 'xtrabackup',
    XTRABACKUP_FULL = 'xtrabackupfull',
    XTRABACKUP_INCR = 'xtrabackupincr',
    MARIABACKUP = 'mariabackup',
    MARIABACKUP_FULL = 'mariabackupfull',
    MARIABACKUP_INCR = 'mariabackupincr',
    NDB = 'ndb',
    PGDUMPALL = 'pgdumpall',
    PGDUMP = 'pgdump',
    PGBASEBACKUP = 'pg_basebackup',
    PGBACKREST_FULL = 'pgbackrestfull',
    PGBACKREST_DIFF = 'pgbackrestdiff',
    PGBACKREST_INC = 'pgbackrestincr',
    MONGODUMP = 'mongodump',
    MONGODB_CONSISTENT = 'mongodb-consistent-backup',
    MONGODB_PERCONA = 'percona-backup-mongodb',
    RDB = 'rdb',
    AOF = 'aof',
    REDIS = 'redis',
    MSSQL_FULL = 'mssqlfull',
    MSSQL_DIFF = 'mssqldiff',
    MSSQL_LOG = 'mssqllog',
    ELASTIC_SNAPSHOT = 'elasticsearch-snapshot',
    PROXYSQL = 'proxysql',
}

export enum CcBackupStorageLocationHostType {
    NODE = 0,
    CONTROLLER = 1,
}

export enum CcBackupMySQLDumpType {
    SCHEMA_ONLY = 'SchemaOnly',
    MYSQL_DB_ONLY = 'MySQLDbOnly',
    DATA_ONLY = 'DataOnly',
    SCHEMA_AND_DATA = 'SchemaAndData',
    COMPLETE_PITR = 'Complete',
}

export default class CcBackup {
    public readonly hostLocations: CcBackupHostStorageLocation[];
    public readonly cloudLocations: CcBackupCloudStorageLocation[];
    public readonly metadata: CcBackupMetadata;

    constructor(props: CcBackupProps) {
        /**
         * Backend is returning cloud_locations in host_locations
         * so conversion workaround is required at the moment
         *
         * @todo rid of this when backend will return location properly
         */
        if (props?.metadata?.method === CcBackupMethod.ELASTIC_SNAPSHOT) {
            const cloudLocations = props.host_locations
                ?.filter((location) => location?.storage_host === 'S3')
                .map(
                    ({ host_location_uuid, root_dir, storage_host, ...rest }) =>
                        ({
                            ...rest,
                            cloud_location_uuid: host_location_uuid,
                            bucket_and_path: root_dir,
                            storageService: storage_host,
                            // @hardcode - at the moment only AWS is supported on backend side
                            provider: CcCloudProviderType.AWS,
                        } as CcBackupCloudStorageLocationProps)
                );
            props.host_locations = props.host_locations?.filter(
                (location) => location?.storage_host !== 'S3'
            );
            if (cloudLocations && cloudLocations?.length > 0) {
                props.cloud_locations = [
                    ...(cloudLocations || []),
                    ...(props.cloud_locations || []),
                ];
            }
        }
        this.hostLocations = props.host_locations
            ? props.host_locations.map(
                  (l) =>
                      new CcBackupHostStorageLocation({
                          ...l,
                      })
              )
            : [];
        this.cloudLocations = props.cloud_locations
            ? props.cloud_locations.map(
                  (l) =>
                      new CcBackupCloudStorageLocation({
                          ...l,
                      })
              )
            : [];
        this.metadata = new CcBackupMetadata(props.metadata);
    }

    public getId(): number {
        return this.metadata.id;
    }

    public getParentId(): number {
        return this.metadata.parentId;
    }

    public getCid(): number {
        return this.metadata.cid;
    }

    public getJobId(): number {
        return this.metadata.jobId;
    }

    public getChildren(): number {
        return this.metadata.children;
    }

    public getMethod(): CcBackupMethod {
        return this.metadata.method;
    }

    public getStatus(): CcBackupStatus {
        return this.metadata.status;
    }

    public getBackupHost(): string {
        return this.metadata.backupHost;
    }

    public getStorageHost(): string {
        return this.metadata.storageHost;
    }

    public getRootDir(): string {
        return this.metadata.rootDir;
    }

    public getConfig(): CcBackupConfigProps | undefined {
        return this.metadata.config;
    }

    public getCreated(): string {
        return this.metadata.created;
    }

    public getEncrypted(): boolean {
        return this.metadata.encrypted;
    }

    public getVerified(): boolean {
        return this.metadata.verified;
    }

    public getUseForPitr(): boolean {
        return this.metadata.useForPitr;
    }

    public getDuration(): number {
        return this.metadata.getDuration();
    }

    public getKey(): string {
        return this.metadata.getKey();
    }

    public getClusterKey(): string {
        return this.metadata.getClusterKey();
    }

    public getControllerKey(): string {
        return this.metadata.getControllerKey();
    }

    public getSize(): number {
        return this.metadata.getSize();
    }

    public canUseForPitr(): boolean {
        return this.metadata.canUseForPitr();
    }

    public isInTheCloud(): boolean {
        return this.cloudLocations.length > 0;
    }

    public isLocal(): boolean {
        return this.hostLocations.length > 0;
    }

    public isMethod(method: CcBackupMethod): boolean {
        return this.getMethod() === method;
    }

    public isMethodPgBackrest() {
        return isBackupMethodPgBackRest(this.getMethod());
    }

    public isIncremental() {
        // @todo check if array in this.metadata.backup or ...files need to handler differently
        return this.metadata.backup?.[0]?.files?.[0]?.type === 'incr';
    }

    public isPartial() {
        const db = this.metadata.backup?.[0]?.db;
        // @todo remove 'complete' when it's gone from backend
        if (!db || db === 'complete' || db === '*') {
            return false;
        }
        return true;
    }

    public getStorageLocations(): (
        | CcBackupHostStorageLocation
        | CcBackupCloudStorageLocation
    )[] {
        return [...this.hostLocations, ...this.cloudLocations];
    }

    public getLocation(uuid: string) {
        return this.getStorageLocations().find(
            (location) => location.getUuid() === uuid
        );
    }
}

export function isBackupMethodXtraBackup(method: CcBackupMethod) {
    return [
        CcBackupMethod.XTRABACKUP,
        CcBackupMethod.XTRABACKUP_FULL,
        CcBackupMethod.XTRABACKUP_INCR,
    ].includes(method);
}

export function isBackupMethodMariaBackup(method: CcBackupMethod) {
    return [
        CcBackupMethod.MARIABACKUP,
        CcBackupMethod.MARIABACKUP_FULL,
        CcBackupMethod.MARIABACKUP_INCR,
    ].includes(method);
}

export function isBackupMethodPgBackRest(method: CcBackupMethod) {
    return [
        CcBackupMethod.PGBACKREST_FULL,
        CcBackupMethod.PGBACKREST_INC,
        CcBackupMethod.PGBACKREST_DIFF,
    ].includes(method);
}

export function isBackupMethodPgBaseBackup(method: CcBackupMethod) {
    return method === CcBackupMethod.PGBASEBACKUP;
}
