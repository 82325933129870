import React, { useEffect } from 'react';
import CcCluster from '../services/models/CcCluster';
import SpaceDescriptions from '../common/Layout/SpaceDescriptions';
import { Space } from 'antd';
import ClusterActionsMenu from '../components/Clusters/Actions/ClusterActionsMenu';
import AppDivider from '../common/AppDivider';
import galera from './dataMocks/clusters/galera';
import postgresWithLb from './dataMocks/clusters/postgresWithLb';
import mongoShards from './dataMocks/clusters/mongoShards';
import CmonMaintenanceService from '../services/cmon/CmonMaintenanceService';
import { debugModal } from './serviceMock/ServiceMockContext';
import mongoReplicaSet from './dataMocks/clusters/mongoReplicaSet';
import mysqlReplication from './dataMocks/clusters/mysqlReplication';
import postgresSingleNode from './dataMocks/clusters/postgresSingleNode';
import redis6SingleNodeShutdown from './dataMocks/clusters/redis6SingleNodeShutdown';
import redis6Sentinel3Nodes from './dataMocks/clusters/redis6Sentinel3Nodes';
import mssqlSingle from './dataMocks/clusters/mssqlSingle';
import mssqlReplication from './dataMocks/clusters/mssqlReplication';
import elasticHA3master from './dataMocks/clusters/elasticHA3master';
import postgresStreamingReplication from './dataMocks/clusters/postgresStreamingReplication';
import useScheduledMaintenancesList from '../components/Maintenance/useScheduledMaintenancesList';

const clusters = [
    galera,
    {
        ...galera,
        cluster_name: 'Galera with auto recovery false',
        node_auto_recovery: false,
    },
    {
        ...galera,
        cluster_name: 'Galera with readonly true and audit log',
        node_auto_recovery: false,
        hosts: galera.hosts.map((h) =>
            h.nodetype === 'galera'
                ? {
                      ...h,
                      readonly: true,
                      audit_log: {
                          plugins: [{ name: 'audit_log', status: 'ACTIVE' }],
                      },
                  }
                : h
        ) as any,
    },
    mysqlReplication,
    {
        ...mysqlReplication,
        cluster_name: 'MySQL Replication - under active maintenance',
        maintenance_mode_active: true,
    },
    postgresSingleNode,
    postgresStreamingReplication,
    {
        ...postgresWithLb,
        cluster_name: 'Postgres with timescaledb',
        hosts: postgresWithLb.hosts.map((h) =>
            h.nodetype === 'postgres'
                ? { ...h, extensions: [{ name: 'timescaledb' }] }
                : h
        ) as any,
    },
    mongoReplicaSet,
    mongoShards,
    redis6SingleNodeShutdown,
    redis6Sentinel3Nodes,
    mssqlSingle,
    mssqlReplication,
    elasticHA3master,
].map((c) => new CcCluster(c as any));

export default DebugClusterActionsMenu;

export type DebugClusterActionsMenuProps = {};

function DebugClusterActionsMenu({}: DebugClusterActionsMenuProps) {
    const {
        refresh: refreshScheduledMaintenances,
    } = useScheduledMaintenancesList({});
    CmonMaintenanceService.addMaintenance = ((data: any, opts: any) => {
        return debugModal(data, opts, {});
    }) as any;

    useEffect(() => {
        (async () => {
            await refreshScheduledMaintenances({});
        })();
    }, []);


    return (
        <Space
            className="DebugClusterActionsMenu"
            direction="vertical"
            size={20}
        >
            {clusters.map((c) => {
                return (
                    <SpaceDescriptions
                        className="DebugClusterActionsMenu_Cluster"
                        data-testid={c.clusterName}
                        key={c.clusterName}
                        title={c.clusterName}
                        direction={'vertical'}
                    >
                        <ClusterActionsMenu key={c.clusterName} cluster={c} />
                        <AppDivider />
                    </SpaceDescriptions>
                );
            })}
        </Space>
    );
}
