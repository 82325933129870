import React from 'react';
import { ButtonProps } from 'antd/lib/button';
import CcCluster from '../../../services/models/CcCluster';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import JobCreateActionButton from '../../Jobs/JobCreateActionButton';

export default ClusterToggleAutoRecoveryButton;

export type ClusterToggleAutoRecoveryButtonProps = ButtonProps & {
    cluster: CcCluster;
    children?: React.ReactNode;
    onSuccess?: () => void;
};

function ClusterToggleAutoRecoveryButton({
    cluster,
    children,
    onSuccess,
    ...rest
}: ClusterToggleAutoRecoveryButtonProps) {
    return (
        <JobCreateActionButton
            clusterId={cluster.clusterId}
            command={
                cluster.clusterAutoRecovery
                    ? CmonJobInstanceCommand.DISABLE_RECOVERY
                    : CmonJobInstanceCommand.ENABLE_RECOVERY
            }
            confirm={false}
            title="Cluster recovery"
            onSuccess={onSuccess}
            switchValue={cluster.clusterAutoRecovery}
            {...rest}
        />
    );
}
