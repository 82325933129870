import SpaceWide from '../SpaceWide';
import React from 'react';
import { ButtonProps } from 'antd/lib/button';
import Switch from '../DataEntry/Switch';

export default ButtonWithSwitch;

type ContentWithSwitchProps = ButtonProps & {
    switchValue?: boolean;
    button: any;
};

function ButtonWithSwitch({
    switchValue,
    button,
    onClick,
    ...rest
}: ContentWithSwitchProps) {
    const handleButtonClick = (...args: any[]) => {
        (onClick as any)?.(...args);
    };
    const buttonProps = {
        onClick: switchValue ? undefined : handleButtonClick,
        ...(button as React.ReactElement).props,
        ...rest,
    };
    return React.cloneElement(button as React.ReactElement, {
        ...buttonProps,
        children:
            switchValue == undefined ? (
                buttonProps.children
            ) : (
                <SpaceWide justify="space-between" onClick={handleButtonClick}>
                    {buttonProps.children}
                    <Switch checked={switchValue} />
                </SpaceWide>
            ),
    });
}
