import './ServiceWizard.less';
import React, { useEffect, useState } from 'react';
import WizardConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardConfiguration';
import WizardSelectCardStep from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardSelectCardStep';
import { CcClusterType } from '../../services/models/CcCluster';
import { NotifyType } from '../Notifications/uiNotification';
import { useNavigate } from 'react-router-dom';
import {
    ClusterConfiguratorFormValues,
    ClusterConfiguratorVendorVersion,
} from './Cluster/ClusterConfigurator';
import ImportWizard, { ImportWizardStep } from './Cluster/Import/ImportWizard';
import DeploymentWizard from './Cluster/Deploy/DeploymentWizard';
import { ServiceClusterWizardStep } from './Cluster/ServiceClusterWizardStep';
import ClusterDatabaseTypeForm, {
    ClusterDatabaseTypeFormValues,
} from './Cluster/ClusterDatabaseTypeForm';
import { useNotificationContext } from '../Notifications/NotificationProvider';

const COMMON_SUPPORTED_DATABASES = [
    'SQL Server',
    'Redis Sentinel',
    'MySQL (Primary/Replica)',
    'MySQL Galera',
    'PostgreSQL (Primary/Replica)',
    'TimescaleDB (Primary/Replica)',
    'MongoDB ReplicaSet',
    'MongoDB Shards',
];

const CREATE_SUPPORTED_DATABASES = [
    'Elasticsearch',
    ...COMMON_SUPPORTED_DATABASES,
];

const IMPORT_SUPPORTED_DATABASES = [...COMMON_SUPPORTED_DATABASES];

export enum ServiceWizardActionType {
    DEPLOY = 'deploy',
    IMPORT = 'import',
}

export enum ServiceWizardStep {
    ACTION = 'action',
    SERVICE = 'service',
    DEPLOY_WIZARD = 'deploy_wizard',
    IMPORT_WIZARD = 'import_wizard',
}

export type ServiceWizardChangeDetails = {
    clusterType?: CcClusterType;
    vendor?: string;
    version?: string;
    actionType?: ServiceWizardActionType;
};
export type ServiceWizardFormValues = ClusterConfiguratorFormValues;

export type ServiceWizardProps = {
    activeStep?: ServiceWizardStep;
    defaultActionType?: ServiceWizardActionType;
    defaultDeployActiveStep?: ServiceClusterWizardStep;
    defaultImportActiveStep?: ImportWizardStep;
    initialClusterType?: CcClusterType;
    initialValues?: ServiceWizardFormValues;
    onActionTypeChange?: (action: ServiceWizardActionType) => void;
    onChange?: (
        step: ServiceWizardStep,
        info: ServiceWizardChangeDetails
    ) => void;
    onClusterTypeChange?: (step: CcClusterType) => void;
    onFormStepErrorInsist?: (err: Error) => void;
    onFormTouchedChange?: (touched: boolean) => void;
    onSuccess?: () => void;
};
const ServiceWizard = ({
    defaultActionType,
    activeStep = ServiceWizardStep.ACTION,
    defaultDeployActiveStep,
    defaultImportActiveStep,
    initialClusterType,
    initialValues,
    onActionTypeChange,
    onChange,
    onClusterTypeChange,
    onFormStepErrorInsist,
    onFormTouchedChange,
    onSuccess,
}: ServiceWizardProps) => {
    const navigate = useNavigate();
    const [step, setStep] = useState<ServiceWizardStep>(activeStep);
    const [wizardVersion, setWizardVersion] = useState<
        ClusterConfiguratorVendorVersion
    >();
    const [actionType, setActionType] = useState<ServiceWizardActionType>(
        defaultActionType || ServiceWizardActionType.DEPLOY
    );
    const [selectedClusterType, setSelectedClusterType] = useState<
        CcClusterType | undefined
    >(initialClusterType);

    const [selectedVendor, setSelectedVendor] = useState<string | undefined>();

    const [selectedVersion, setSelectedVersion] = useState<
        string | undefined
    >();

    const { notifyJobCreationSuccess } = useNotificationContext();

    useEffect(() => {
        if (onChange) {
            onChange(step, {
                clusterType: selectedClusterType,
                vendor: selectedVendor,
                version: selectedVersion,
                actionType,
            });
        }
    }, [step]);

    const handleActionSelect = (value: ServiceWizardActionType) => {
        setActionType(value);
        setStep(ServiceWizardStep.SERVICE);
        onActionTypeChange && onActionTypeChange(value);
    };

    const handleClusterDatabaseTypeValuesChange = (
        values: ClusterDatabaseTypeFormValues
    ) => {
        if (
            values.vendor !== selectedVendor ||
            selectedClusterType !== values.clusterType
        ) {
            setWizardVersion(undefined);
        }

        setSelectedClusterType(values.clusterType);
        onClusterTypeChange &&
            onClusterTypeChange(values.clusterType as CcClusterType);

        setSelectedVendor(values.vendor);
        setSelectedVersion(values.version);
    };

    const handleClusterDatabaseTypeContinueClick = () => {
        setStep(
            actionType === ServiceWizardActionType.IMPORT
                ? ServiceWizardStep.IMPORT_WIZARD
                : ServiceWizardStep.DEPLOY_WIZARD
        );
    };
    const handleClusterDatabaseTypeBackClick = () => {
        setStep(ServiceWizardStep.ACTION);
        setSelectedClusterType(undefined);
    };
    const handleDeploymentWizardCancel = () => {
        setStep(ServiceWizardStep.SERVICE);
    };
    const handleServiceSuccess = () => {
        if (onSuccess) {
            onSuccess();
        }
        notifyJobCreationSuccess({
            type: NotifyType.TOAST,
            content: 'Deployment could takes some time, please wait',
            navigate,
        });
    };

    const handleServiceFormTouchedChange = (touched: boolean) => {
        if (onFormTouchedChange) {
            onFormTouchedChange(touched);
        }
    };
    const handleServiceFormStepErrorInsist = (err: Error) => {
        if (onFormStepErrorInsist) {
            onFormStepErrorInsist(err);
        }
    };

    const handleWizardValuesChange = (values: ServiceWizardFormValues) => {
        const version = values?.details?.version;
        if (version) {
            setWizardVersion({
                name: version,
                value: version,
            });
        }
    };

    return (
        <WizardConfiguration
            activeStep={step}
            steps={[
                {
                    step: ServiceWizardStep.ACTION,
                    content: (
                        <WizardSelectCardStep
                            title="Deploy a cluster"
                            description="Deploy a cluster managed by ClusterControl’s virtual DBA. Monitor failures, automate backups, node and cluster recovery."
                            onSelect={(value) =>
                                handleActionSelect(
                                    value as ServiceWizardActionType
                                )
                            }
                        >
                            <WizardSelectCardStep.Item
                                icon={
                                    <img
                                        src={require('./img-service-deploy.svg')}
                                        alt="Create a database cluster"
                                    />
                                }
                                backgroundType="squares"
                                title="Create a database cluster"
                                action={ServiceWizardActionType.DEPLOY}
                                description="Choose a database technology, configure and
                                    create an open source database service
                                    within a few minutes."
                                descriptionSecondary={`Supported databases: ${getSupportedDatabases(
                                    'create'
                                )}`}
                                buttonTitle="Continue"
                            />
                            <WizardSelectCardStep.Item
                                icon={
                                    <img
                                        src={require('./img-service-import.svg')}
                                        alt="Import a database cluster"
                                    />
                                }
                                backgroundType="circles"
                                title="Import a database cluster"
                                action={ServiceWizardActionType.IMPORT}
                                description="Choose a database technology, configure and
                                    import an open source database within a few
                                    minutes."
                                descriptionSecondary={`Supported databases: ${getSupportedDatabases(
                                    'import'
                                )}`}
                                buttonTitle="Continue"
                            />
                        </WizardSelectCardStep>
                    ),
                },
                {
                    step: ServiceWizardStep.SERVICE,
                    className: 'ServiceWizard-select-type',
                    content: (
                        <ClusterDatabaseTypeForm
                            externalVersion={wizardVersion}
                            actionType={actionType}
                            onValuesChange={
                                handleClusterDatabaseTypeValuesChange
                            }
                            onContinue={handleClusterDatabaseTypeContinueClick}
                            onBack={handleClusterDatabaseTypeBackClick}
                        />
                    ),
                },
                {
                    step: ServiceWizardStep.DEPLOY_WIZARD,
                    content: (
                        <div className="ServiceWizard_deployment-wizard-wrapper">
                            <DeploymentWizard
                                initialValues={initialValues}
                                defaultActiveStep={defaultDeployActiveStep}
                                onCancel={handleDeploymentWizardCancel}
                                clusterType={
                                    selectedClusterType as CcClusterType
                                }
                                vendor={selectedVendor}
                                version={selectedVersion}
                                onValuesChange={handleWizardValuesChange}
                                onSuccess={handleServiceSuccess}
                                onTouchedChange={handleServiceFormTouchedChange}
                                onStepErrorInsist={
                                    handleServiceFormStepErrorInsist
                                }
                            />
                        </div>
                    ),
                },
                {
                    step: ServiceWizardStep.IMPORT_WIZARD,
                    content: (
                        <div className="ServiceWizard_import-wizard-wrapper">
                            <ImportWizard
                                initialValues={initialValues}
                                defaultActiveStep={defaultImportActiveStep}
                                onCancel={handleDeploymentWizardCancel}
                                clusterType={
                                    selectedClusterType as CcClusterType
                                }
                                onSuccess={handleServiceSuccess}
                                onTouchedChange={handleServiceFormTouchedChange}
                                onStepErrorInsist={
                                    handleServiceFormStepErrorInsist
                                }
                            />
                        </div>
                    ),
                },
            ]}
        />
    );
};

export function getServiceWizardActionTypeText(type?: ServiceWizardActionType) {
    switch (type) {
        case ServiceWizardActionType.IMPORT:
            return 'Import';
        case ServiceWizardActionType.DEPLOY:
        default:
            return 'Deploy';
    }
}

function getSupportedDatabases(type: 'create' | 'import') {
    const list = [
        ...(type === 'create'
            ? CREATE_SUPPORTED_DATABASES
            : IMPORT_SUPPORTED_DATABASES),
    ];
    const last = list.pop();
    return `${list.join(', ')} and ${last}`;
}

export default ServiceWizard;
