import React, { useEffect, useMemo, useState } from 'react';
import AppRow from '../../../common/AppRow';
import { Col, Form, Input, Select, Space } from 'antd';
import FormItem from '../../../common/DataEntry/FormItem';
import { ServiceWizardActionType } from '../ServiceWizard';
import {
    ClusterConfigurator,
    ClusterConfiguratorClusterType,
    ClusterConfiguratorVendor,
    ClusterConfiguratorVendorVersion,
} from './ClusterConfigurator';
import {
    CcClusterType,
    CcClusterVendor,
} from '../../../services/models/CcCluster';
import { getDeploymentConfigurator } from './Deploy/DeploymentWizard';
import { getImportConfigurator } from './Import/ImportWizard';
import ClusterTypeCoverView from '../../../common/DataDisplay/ClusterTypeCoverView';
import FormFooter from '../../../common/FormFooter';
import { getCoverViewIcon } from '../../../common/DataDisplay/CoverView';
import useUserClusterType from '../../Clusters/useUserClusterType';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import ClusterTypeVersionsField from './FormParts/ClusterTypeVersionsField';

export default ClusterDatabaseTypeForm;

export type ClusterDatabaseTypeFormProps = {
    actionType?: ServiceWizardActionType;
    onValuesChange?: (values: ClusterDatabaseTypeFormValues) => void;
    onContinue?: () => void;
    onBack?: () => void;
    externalVersion?: ClusterConfiguratorVendorVersion;
};

export interface ClusterDatabaseTypeFormValues {
    clusterType?: CcClusterType;
    vendor?: string;
    version?: string;
}

function ClusterDatabaseTypeForm({
    actionType = ServiceWizardActionType.DEPLOY,
    onValuesChange,
    onContinue,
    onBack,
    externalVersion,
}: ClusterDatabaseTypeFormProps) {
    const [form] = Form.useForm();

    const [selectedClusterType, setSelectedClusterType] = useState<
        ClusterConfiguratorClusterType | undefined
    >();
    const [selectedVendor, setSelectedVendor] = useState<
        ClusterConfiguratorVendor | undefined
    >();
    const [selectedVersion, setSelectedVersion] = useState<
        ClusterConfiguratorVendorVersion | undefined
    >();

    const userClusterType = useUserClusterType();

    const clusterTypes = useMemo(() => {
        return actionType ? getClusterConfiguratorClusterTypes(actionType) : [];
    }, [actionType]);

    const vendors: ClusterConfiguratorVendor[] = useMemo(() => {
        if (selectedClusterType) {
            const configurator = getClusterConfigurator(
                actionType,
                selectedClusterType.key
            );
            const vendors = configurator.getVendors();
            if (actionType === ServiceWizardActionType.DEPLOY) {
                setSelectedVendor(
                    vendors.find(
                        (vendor) =>
                            vendor.value ===
                            configurator.getDefaults()?.details?.vendor
                    ) || vendors[0]
                );
            }
            return vendors;
        } else {
            return [];
        }
    }, [selectedClusterType, actionType]);

    const versions = useMemo(() => {
        if (selectedClusterType && selectedVendor) {
            const configurator = getClusterConfigurator(
                actionType,
                selectedClusterType.key
            );
            const versions = selectedVendor.versions;

            if (selectedVendor.extended) {
                /**
                 * If we're going to load versions from backend then
                 * we don't need use default version from configurator
                 */
                setSelectedVersion(undefined);
                form.setFieldsValue({ version: undefined });
            } else {
                const defaultVersion =
                    versions.find(
                        (version) =>
                            version.value ===
                            configurator.getDefaults()?.details?.version
                    ) || versions[0];
                setSelectedVersion(defaultVersion);
            }
            return versions;
        } else {
            return [];
        }
    }, [selectedVendor]);

    useEffect(() => {
        if (
            selectedClusterType &&
            (actionType === ServiceWizardActionType.IMPORT ||
                (actionType === ServiceWizardActionType.DEPLOY &&
                    selectedVendor))
        ) {
            onValuesChange?.({
                clusterType: selectedClusterType.key,
                vendor: selectedVendor?.value,
                version: selectedVersion?.value,
            });
        }
    }, [selectedClusterType, selectedVendor, selectedVersion]);

    useEffect(() => {
        if (selectedVendor) {
            form.setFieldsValue({ vendor: selectedVendor.value });
        }
    }, [selectedVendor]);

    useEffect(() => {
        if (selectedVersion) {
            form.setFieldsValue({ version: selectedVersion.value });
        }
    }, [selectedVersion]);

    useEffect(() => {
        if (userClusterType && selectedClusterType === undefined) {
            const config = clusterTypes.find(
                (type) => type.key === userClusterType
            );
            if (config) {
                setSelectedClusterType(config);
                form.setFieldsValue({ clusterType: userClusterType });
            }
        }
    }, [userClusterType, selectedClusterType]);

    useEffect(() => {
        if (externalVersion) {
            setSelectedVersion(externalVersion);
        }
    }, [externalVersion]);

    const handleValuesChange = (values: ClusterDatabaseTypeFormValues) => {
        if (values.clusterType) {
            setSelectedClusterType(
                clusterTypes.find((type) => type.key === values.clusterType)
            );
            setSelectedVendor(undefined);
            setSelectedVersion(undefined);
        }
        if (values.vendor) {
            setSelectedVendor(
                vendors.find((vendor) => vendor.value === values.vendor)
            );
            setSelectedVersion(undefined);
        }

        if (values.version) {
            if (selectedVendor?.extended) {
                /**
                 * if versions are going to be loaded from backend
                 * then we ignore versions from configurator
                 */
                setSelectedVersion({
                    name: values.version,
                    value: values.version,
                });
            } else {
                setSelectedVersion(
                    versions.find((version) => version.value === values.version)
                );
            }
        }
    };

    const handleVersionsLoaded = (versions: string[]) => {
        /**
         * External version should not be overridden
         */
        if (!externalVersion) {
            const version = versions[0];
            setSelectedVersion({
                name: version,
                value: version,
            });
        }
    };

    const configurator =
        selectedClusterType?.key &&
        getDeploymentConfigurator(selectedClusterType?.key);

    const loadVersions = !!(
        selectedVendor?.extended &&
        selectedVendor?.value &&
        selectedClusterType?.key &&
        configurator
    );

    const handleBackClick = () => {
        setSelectedClusterType(undefined);
        setSelectedVendor(undefined);
        setSelectedVersion(undefined);
        form.resetFields();
        onBack?.();
    };
    return (
        <div className="ClusterDatabaseTypeForm">
            <Form
                form={form}
                layout="vertical"
                onValuesChange={handleValuesChange}
                onFinish={onContinue}
            >
                <AppRow gutter={24}>
                    <Col md={8} sm={24}>
                        <FormItem
                            label="Database"
                            name={['clusterType']}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select a database.',
                                },
                            ]}
                        >
                            <Select
                                data-testid="cluster-database-type-form-select-database"
                                options={clusterTypes.map((item) => ({
                                    key: item.key,
                                    value: item.key,
                                    label: (
                                        <Space>
                                            {getCoverViewIcon(
                                                item.icon,

                                                20
                                            )}
                                            <span>{`${item.name}${
                                                item.disabled
                                                    ? ` (coming soon...)`
                                                    : ''
                                            }`}</span>
                                        </Space>
                                    ),
                                }))}
                            />
                        </FormItem>
                    </Col>
                    {actionType === ServiceWizardActionType.DEPLOY ? (
                        <Col md={8} sm={24}>
                            <FormItem
                                label="Vendor"
                                name={['vendor']}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select a vendor.',
                                    },
                                ]}
                            >
                                <Select
                                    data-testid="cluster-database-type-form-select-vendor"
                                    options={vendors.map(
                                        (
                                            vendor: ClusterConfiguratorVendor
                                        ) => ({
                                            key: vendor.value,
                                            value: vendor.value,
                                            label: (
                                                <Space>
                                                    {getCoverViewIcon(
                                                        vendor.icon ||
                                                            selectedClusterType?.icon,
                                                        20
                                                    )}
                                                    <span>{vendor.name}</span>
                                                </Space>
                                            ),
                                        })
                                    )}
                                />
                            </FormItem>
                        </Col>
                    ) : null}
                    {actionType === ServiceWizardActionType.DEPLOY ? (
                        <Col md={8} sm={24}>
                            {loadVersions ? (
                                <ClusterTypeVersionsField
                                    label={
                                        <Space>
                                            Version
                                            <InfoIcon
                                                info={
                                                    <span>
                                                        Version patch number can
                                                        be specified on "Node
                                                        configuration" step
                                                    </span>
                                                }
                                            />
                                        </Space>
                                    }
                                    name={['version']}
                                    form={form}
                                    configurator={configurator}
                                    clusterType={selectedClusterType?.key}
                                    vendor={
                                        selectedVendor?.value as CcClusterVendor
                                    }
                                    onVersionsLoaded={handleVersionsLoaded}
                                />
                            ) : (
                                <FormItem
                                    label={<span>Version</span>}
                                    name={['version']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a version.',
                                        },
                                    ]}
                                >
                                    <Select
                                        data-testid="cluster-database-type-form-select-version"
                                        options={versions.map(
                                            (version: any) => ({
                                                key: version.value,
                                                value: version.value,
                                                label: version.name,
                                            })
                                        )}
                                    />
                                </FormItem>
                            )}
                        </Col>
                    ) : null}
                </AppRow>
                <AppRow gutter={24}>
                    <Col span={24}>
                        <ClusterTypeCoverView
                            clusterTypeConfig={selectedClusterType}
                            vendor={selectedVendor}
                            version={selectedVersion}
                            emptyDescription={
                                actionType === ServiceWizardActionType.DEPLOY
                                    ? 'Choose a database, vendor and version that you want to deploy'
                                    : 'Choose a database that you want to import'
                            }
                        />
                    </Col>
                </AppRow>
                <AppRow gutter={24}>
                    <Col span={24}>
                        <FormFooter
                            showCancelButton={true}
                            showSubmitButton={true}
                            cancelButtonText="Back"
                            submitButtonText="Continue"
                            onCancel={handleBackClick}
                            noDivider={true}
                            submitButtonProps={{
                                disabled: selectedClusterType?.disabled,
                            }}
                        />
                    </Col>
                </AppRow>
            </Form>
        </div>
    );
}

function getClusterConfigurator(
    action: ServiceWizardActionType,
    clusterType: CcClusterType
) {
    switch (action) {
        case ServiceWizardActionType.DEPLOY:
            return getDeploymentConfigurator(clusterType);
        case ServiceWizardActionType.IMPORT:
            return getImportConfigurator(clusterType);
    }
}

function getClusterConfiguratorClusterTypes(action: ServiceWizardActionType) {
    let types = [];
    switch (action) {
        case ServiceWizardActionType.DEPLOY:
            types = ClusterConfigurator.getDeployClusterTypes();
            break;
        case ServiceWizardActionType.IMPORT:
            types = ClusterConfigurator.getDeployClusterTypes().map((item) => {
                // temporary disable elastic import
                if (item.key === CcClusterType.TYPE_ELASTIC) {
                    return { ...item, disabled: true };
                }
                return item;
            });
            break;
    }
    return types.map((type) => ({
        ...type,
        disabled: type.disabled || false,
        value: type.key,
    }));
}
