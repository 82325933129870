import galera from '../../clusters/galera';
import mysqlReplication from '../../clusters/mysqlReplication';
import postgresSingleNode from '../../clusters/postgresSingleNode';
import mongodb1 from '../../clusters/mongodb1';
import redis6dev from '../../clusters/redis6SingleNodeShutdown';
import mssqlSingle from '../../clusters/mssqlSingle';
import elasticHA3master from '../../clusters/elasticHA3master';
import mongodb2 from '../../clusters/mongodb2';
import mssqlReplication from '../../clusters/mssqlReplication';
import redis6sentinel from '../../clusters/redis6Sentinel3Nodes';
import { CcLicenseType } from '../../../../services/models/CcLicense';
import postgresSingleNodePgvector
    from "../../clusters/postgresSingleNodePgvector";
const clusters = [
    galera,
    mysqlReplication,
    postgresSingleNode,
    postgresSingleNodePgvector,
    mongodb1,
    mongodb2,
    redis6dev,
    redis6sentinel,
    mssqlSingle,
    mssqlReplication,
    elasticHA3master,
];
const result = {
    license: {
        class_name: 'CmonLicense',
        type: CcLicenseType.Enterprise,
        used_nodes: 0,
        licensed_nodes: 100,
        days_left: 999,
        valid_date: true,
        expiration_date: '2099-12-12',
        email_address: 'test@severalnines.com',
    },
    license_check: {
        class_name: 'CmonLicenseCheck',
        has_license: true,
        expires_days: 999,
        status_text: 'License found.',
    },
    clusters,
    total: clusters.length,
};

export default result;
