import React, { useMemo } from 'react';
import { Col, Form, Input, Row, Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import Alert from '@severalnines/bar-frontend-components/build/lib/Feedback/Alert';
import { FormInstance } from 'antd/lib/form';
import {
    CcClusterType,
    CcClusterVendor,
} from '../../../../services/models/CcCluster';
import PasswordInput from '../../../../common/DataEntry/PasswordInput';
import FormItemInlineSwitch from '../../../../common/DataEntry/FormItemInlineSwitch';
import VendorRepositoryField from '../../../../common/Form/Fields/VendorRepositoryField';
import NodeConfigurationTemplateField from '../../../../common/Form/Fields/NodeConfigurationTemplateField';
import SemiSynchronousReplicationSwitch from '../../../../common/Form/Fields/SemiSynchronousReplicationSwitch';
import DataDirectoryField from '../../../../common/Form/Fields/DataDirectoryField';
import SslEncryptionField from '../FormParts/SslEncryptionField';
import { ClusterConfigurator } from '../ClusterConfigurator';
import ClusterTypeVersionsField from '../FormParts/ClusterTypeVersionsField';

export default MysqlNodeConfiguration;

export type MysqlNodeConfigurationProps = {
    form: FormInstance;
    clusterType: CcClusterType;
    configurator: typeof ClusterConfigurator;
    hasRepository?: boolean;
    hasTemplate?: boolean;
    hasSslEncryption?: boolean;
    hasSemiSynchronous?: boolean;
    hasPrimaryCluster?: boolean;
};

function MysqlNodeConfiguration({
    form,
    clusterType,
    configurator,
    hasRepository = true,
    hasTemplate = true,
    hasSslEncryption = false,
    hasSemiSynchronous = false,
    hasPrimaryCluster,
}: MysqlNodeConfigurationProps) {
    const { details } = form.getFieldsValue(true);
    const vendor = useMemo(() => {
        return configurator
            .getVendors()
            .find((item) => item.value === details.vendor);
    }, [details.vendor, configurator]);

    return (
        <div className="MysqlNodeConfiguration">
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Node configuration</h3>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        name={['nodeConfig', 'serverPort']}
                        label={
                            <Space>
                                Server port
                                <InfoIcon info="The MySQL server port." />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message: 'Please enter the MySQL service port.',
                            },
                        ]}
                    >
                        <Input placeholder="Enter server port" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <DataDirectoryField
                        name={['nodeConfig', 'serverDataDirectory']}
                        label={
                            <Space>
                                Server data directory
                                <InfoIcon info="Location of MySQL data directory. Default is '/var/lib/mysql'." />
                            </Space>
                        }
                    />
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        name={['nodeConfig', 'adminUser']}
                        label={
                            <Space>
                                Admin/Root user
                                <InfoIcon
                                    info={
                                        <Space direction="vertical">
                                            <b>Mysql 'Admin/root' user </b>
                                            <ul>
                                                <li>
                                                    Specify the 'Admin/Root'
                                                    user for mysql server.
                                                </li>
                                                <li>
                                                    The root/admin user must
                                                    have 'WITH GRANT' option in
                                                    order to create the
                                                    necessary monitor user
                                                    'cmon'.
                                                </li>
                                                <li>
                                                    The 'Admin/Root' user must
                                                    be allowed to connect on
                                                    localhost.
                                                </li>
                                            </ul>
                                        </Space>
                                    }
                                />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message:
                                    'Please enter the mysql admin username.',
                            },
                        ]}
                    >
                        <Input placeholder="Enter the admin username" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        name={['nodeConfig', 'adminPassword']}
                        label={
                            <Space>
                                Admin/Root password
                                <InfoIcon info="The admin password." />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message: 'Please enter the admin password.',
                            },
                        ]}
                    >
                        <PasswordInput placeholder="Enter the admin password" />
                    </Form.Item>
                </Col>
                {hasRepository ? (
                    <Col xs={24} sm={24} md={12}>
                        <VendorRepositoryField
                            name={['nodeConfig', 'repository']}
                            form={form}
                        />
                    </Col>
                ) : null}
                {vendor?.extended && hasRepository ? (
                    <Col xs={24} sm={24} md={12}>
                        <ClusterTypeVersionsField
                            name={['details', 'version']}
                            form={form}
                            configurator={configurator}
                            clusterType={clusterType}
                            vendor={vendor.value as CcClusterVendor}
                            patchNumber={true}
                            autocomplete={true}
                            blockOnError={false}
                        />
                    </Col>
                ) : null}
                {hasTemplate ? (
                    <Col xs={24} sm={24} md={12}>
                        <NodeConfigurationTemplateField
                            name={['nodeConfig', 'configurationTemplate']}
                            vendor={details.vendor}
                            version={details.version}
                            clusterType={clusterType}
                        />
                    </Col>
                ) : null}
            </Row>
            <Row gutter={[24, 0]}>
                {hasSslEncryption ? (
                    <Col xs={24} sm={24} md={12}>
                        <SslEncryptionField clusterType={clusterType} />
                    </Col>
                ) : null}
                {hasSemiSynchronous ? (
                    <Col xs={24} sm={24} md={12}>
                        <SemiSynchronousReplicationSwitch
                            name={['nodeConfig', 'semiSynchronous']}
                        />
                    </Col>
                ) : null}
                {hasPrimaryCluster ? (
                    <Col xs={24} sm={24} md={12}>
                        <FormItemInlineSwitch
                            justify
                            name={['nodeConfig', 'readonly']}
                            label={<span>Create cluster as readonly</span>}
                            valuePropName="checked"
                            extraOffSwitch={
                                <Alert
                                    message="Allowing writes on the slave cluster may cause inconsistent data."
                                    type="warning"
                                    showIcon={true}
                                />
                            }
                        />
                    </Col>
                ) : null}
            </Row>
        </div>
    );
}
