import React, { useEffect, useMemo } from 'react';
import CcCluster, {
    CcClusterTechnology,
} from '../../../services/models/CcCluster';
import AppTable, { AppTableProps } from '../../../common/DataDisplay/AppTable';
import { Checkbox, Form, Space, Spin, Tag } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import TypographyText from '../../../common/TypographyText';
import FormFooter from '../../../common/FormFooter';
import CcPkgInfo from '../../../services/models/CcPkgInfo';
import { InfoCircleOutlined } from '@ant-design/icons';
import WrapFormat, { WrapFormatProps } from '../../../common/Format/WrapFormat';
import ClusterNodesCheckAvailablePackages from './ClusterNodesCheckAvailablePackages';
import useCreateJob from '../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import NodeFormat, { getNodeHostWithDesc } from '../../Nodes/NodeFormat';
import { getNodeTypeText } from '../../Nodes/NodeTypeFormat';
import useCheckboxGroup from '../../../common/hooks/useCheckboxGroup';
import useClusterAvailableUpgrades, {
    CLusterAvailableUpgradeRecord,
} from './useClusterAvailableUpgrades';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import StatusFormat, {
    StatusFormatStatus,
} from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import SpaceWide from '../../../common/SpaceWide';

export default ClusterNodesUpgradeForm;
export type ClusterNodesUpgradeFormProps = {
    cluster: CcCluster;
    onCancel?: () => void;
    onSuccess?: () => void;
};

function ClusterNodesUpgradeForm({
    cluster,
    onCancel,
    onSuccess,
    ...rest
}: ClusterNodesUpgradeFormProps) {
    const [form] = useForm();

    const {
        dataSource,
        loading: loadingPackages,
        refresh: refreshPackages,
    } = useClusterAvailableUpgrades({
        cluster,
        isMongo: cluster.isTechnology(CcClusterTechnology.TECHNOLOGY_MONGODB),
    });

    const { send: upgradeSend, loading: upgradeLoading } = useCreateJob({
        title: 'Upgrade packages',
        command: CmonJobInstanceCommand.UPGRADE_CLUSTER,
        clusterId: cluster.clusterId,
        onSuccess,
    });

    const checkItems = useMemo(() => {
        return dataSource.map((record) => {
            return {
                isGroup: record.node === undefined,
                children: record.children,
            };
        });
    }, [dataSource]);

    const { toggle, toggleAll, calcCommonState } = useCheckboxGroup({
        items: checkItems,
        form,
        itemName: 'nodes',
    });

    useEffect(() => {
        if (cluster.isTechnology(CcClusterTechnology.TECHNOLOGY_MONGODB)) {
            toggleAll(true);
        }
    }, []);

    const columns: AppTableProps['columns'] = [
        {
            title: 'Node',
            key: 'node',
            render: (record: CLusterAvailableUpgradeRecord) =>
                record.node ? (
                    getNodeHostWithDesc(record.node)
                ) : (
                    <TypographyText strong={true}>{record.name}</TypographyText>
                ),
            onCell: (record: CLusterAvailableUpgradeRecord) => ({
                colSpan: record.node ? 1 : 3,
            }),
        },
        {
            title: 'Node info',
            key: 'info',
            render: (record: CLusterAvailableUpgradeRecord) =>
                record.node && (
                    <NodeFormat
                        node={record.node}
                        showText={false}
                        extraRight={
                            <TypographyText>
                                {`${getNodeTypeText(
                                    record.node.nodetype
                                )} | ${record.node.distribution?.getFullName()}`}
                            </TypographyText>
                        }
                    />
                ),
            onCell: (record: CLusterAvailableUpgradeRecord) => ({
                colSpan: record.node ? 1 : 0,
            }),
        },
        {
            title: 'Packages',
            width: 1, // to shrink the column to the content size
            key: 'packages',
            render: (record: CLusterAvailableUpgradeRecord) => {
                if (loadingPackages) {
                    return '';
                }
                const newVersions = record?.upgradablePackages || [];
                return newVersions.length > 0 ? (
                    <TypographyText nowrap={true}>
                        <Space>
                            <StatusFormat status={StatusFormatStatus.warning}>
                                Upgrade available
                            </StatusFormat>
                            <PackagesTableWrapFormat
                                tableProps={{ packages: record.packages }}
                            />
                        </Space>
                    </TypographyText>
                ) : (
                    <SpaceWide align="center" justify="center">
                        <InfoIcon
                            info={
                                <span>
                                    No upgradable packages found please click
                                    "Check for upgrades" to update the status
                                </span>
                            }
                        />
                    </SpaceWide>
                );
            },
            onCell: (record: CLusterAvailableUpgradeRecord) => ({
                colSpan: record.node ? 1 : 0,
            }),
        },
        {
            title: (
                <Form.Item noStyle={true} shouldUpdate={true}>
                    {() => {
                        const state = calcCommonState();
                        return (
                            <Checkbox
                                disabled={cluster.isTechnology(
                                    CcClusterTechnology.TECHNOLOGY_MONGODB
                                )}
                                checked={state === true}
                                indeterminate={state === 0}
                                onChange={(e) => {
                                    toggleAll(e.target.checked);
                                }}
                            />
                        );
                    }}
                </Form.Item>
            ),
            key: 'check',
            align: 'center',
            render: (
                record: CLusterAvailableUpgradeRecord,
                r: any,
                index: number
            ) => {
                return (
                    <Form.Item noStyle={true} shouldUpdate={true}>
                        {() => {
                            const item = form.getFieldValue(['nodes', index]);
                            // console.log('item', item, index);
                            return (
                                <Form.Item
                                    name={['nodes', index]}
                                    valuePropName="checked"
                                    noStyle={true}
                                >
                                    <Checkbox
                                        disabled={cluster.isTechnology(
                                            CcClusterTechnology.TECHNOLOGY_MONGODB
                                        )}
                                        indeterminate={item === 0}
                                    />
                                </Form.Item>
                            );
                        }}
                    </Form.Item>
                );
            },
        },
    ];

    const handleCancel = () => {
        onCancel?.();
    };

    const handleValuesChange = ({ nodes }: any) => {
        const key = Object.keys(nodes).map((key) => +key)[0];
        toggle(+key, nodes[key]);
    };

    const handleCheckUpgradesFinish = async () => {
        await refreshPackages({});
    };

    const handleRow = (
        record: CLusterAvailableUpgradeRecord,
        index: number
    ) => {
        return {
            'data-testid': `upgrade-type-table-row-${index}`,
            onClick: () => {
                const selected = form.getFieldValue(['nodes', index]);
                handleValuesChange({
                    nodes: {
                        [index]: !selected,
                    },
                });
            },
            style: { cursor: 'pointer' },
        };
    };

    const handleSubmit = async () => {
        if (cluster.isTechnology(CcClusterTechnology.TECHNOLOGY_MONGODB)) {
            await upgradeSend({
                clusterId: cluster.clusterId,
            });
        } else {
            const selectedNodes = form.getFieldValue('nodes') || {};
            const nodes = Object.entries(selectedNodes)
                .map(([key, value], index) => {
                    if (value) {
                        return dataSource[index]?.node;
                    }
                    return undefined;
                })
                .filter((node) => !!node);

            if (nodes.length > 0) {
                await upgradeSend({
                    clusterId: cluster.clusterId,
                    exec_upgrade_script: true,
                    force: true,
                    nodes: nodes.map((node) => ({
                        hostname: node?.hostname,
                        port: node?.port,
                    })),
                });
            }
        }
    };

    return (
        <div className="ClusterNodesUpgradeForm" style={{ margin: '1rem' }}>
            <Spin spinning={loadingPackages}>
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    onValuesChange={handleValuesChange}
                    layout="vertical"
                >
                    <AppTable
                        size="middle"
                        onRow={
                            cluster.isTechnology(
                                CcClusterTechnology.TECHNOLOGY_MONGODB
                            )
                                ? () => {}
                                : handleRow
                        }
                        dataSource={dataSource}
                        expandable={{
                            childrenColumnName: 'not_children',
                        }}
                        columns={columns}
                    />
                    <Form.Item noStyle={true} shouldUpdate={true}>
                        {() => {
                            const hasSelected = Object.values(
                                form.getFieldValue('nodes') || {}
                            ).some((value) => value);
                            return (
                                <FormFooter
                                    noDivider={true}
                                    submitButtonText="Upgrade"
                                    submitButtonProps={{
                                        disabled: !hasSelected,
                                    }}
                                    showSubmitButton={true}
                                    onCancel={handleCancel}
                                    extraLeft={true}
                                    loading={upgradeLoading}
                                >
                                    <ClusterNodesCheckAvailablePackages
                                        cluster={cluster}
                                        onFinish={handleCheckUpgradesFinish}
                                    />
                                </FormFooter>
                            );
                        }}
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    );
}

type PackagesTableProps = {
    packages?: CcPkgInfo[];
    showAvailable?: boolean;
};

function PackagesTable({ packages, showAvailable = true }: PackagesTableProps) {
    const newVersions = useMemo(
        () =>
            (showAvailable &&
                packages?.filter(
                    (pkg) => pkg.installedVersion !== pkg.availableVersion
                )) ||
            [],
        [packages]
    );

    const dataSource = newVersions.length ? newVersions : packages;

    const currentVersionColumn = {
        title: 'Current version',
        key: 'current',
        render: (record: CcPkgInfo) =>
            `${record.packageName} | ${record.installedVersion}`,
    };
    const newVersionColumn = {
        title: (
            <Space size={10}>
                <TypographyText>Available version</TypographyText>
                <Tag color="gold">NEW</Tag>
            </Space>
        ),
        key: 'available',
        render: (record: CcPkgInfo) =>
            `${record.packageName} | ${record.availableVersion}`,
    };
    const columns: AppTableProps['columns'] =
        newVersions.length > 0
            ? [currentVersionColumn, newVersionColumn]
            : [currentVersionColumn];

    return (
        <AppTable
            size="small"
            onRow={() => ({})}
            dataSource={dataSource}
            columns={columns}
        />
    );
}

type PackagesTableWrapFormatProps = WrapFormatProps & {
    tableProps?: PackagesTableProps;
};

function PackagesTableWrapFormat({
    tableProps = {},
    children,
    ...rest
}: PackagesTableWrapFormatProps) {
    return (
        <WrapFormat
            popoverProps={{
                destroyTooltipOnHide: true,
                overlayInnerStyle: {
                    minWidth: '240px',
                    padding: '5px',
                },
                mouseEnterDelay: 0,
                trigger: 'hover',
            }}
            popoverContent={<PackagesTable {...tableProps} />}
            {...rest}
        >
            {children || <InfoCircleOutlined />}
        </WrapFormat>
    );
}
