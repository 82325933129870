import merge from 'deepmerge';
import RedisConfigurator, {
    RedisFormValues,
} from '../../Redis/RedisConfigurator';
import { RepositoryItemKey } from '../../../../../common/DataEntry/RepositoryInput';
import { FormInstance } from 'antd/lib/form';
import { Alert } from 'antd';
import React from 'react';
import NodeConfigurationSummary, {
    NodeConfigurationSummaryProps,
} from '../../NodeConfigurationSummary';

export default class RedisDeploymentConfigurator extends RedisConfigurator {
    public static getDefaults(): RedisFormValues {
        return merge(RedisConfigurator.getDefaults(), {
            details: {
                vendor: 'redis',
                version: '7',
            },
            nodeConfig: {
                sslEncryption: true,
            },
        });
    }

    public static getJobOptions(formValues: RedisFormValues): any {
        return merge(RedisConfigurator.getJobOptions(formValues), {
            enable_uninstall: true,
            job: {
                title: 'Create Redis Sentinel Cluster',
            },
        });
    }

    public static getNodeConfigurationSummary(
        props: NodeConfigurationSummaryProps
    ): React.ReactNode {
        return <NodeConfigurationSummary hasSslEncryption={true} {...props} />;
    }

    public static getTopologyStep(form: FormInstance) {
        return (
            <div>
                {RedisConfigurator.getTopologyStep(form)}
                <Alert
                    message={
                        <ol>
                            <li>
                                Redis sentinel will be co-located with the Redis
                                instances by default.
                            </li>
                            <li>
                                Total number of nodes should be an odd number:
                                1, 3 or 5
                            </li>
                        </ol>
                    }
                    type="info"
                    showIcon={true}
                />
            </div>
        );
    }
}
