import { useState } from 'react';
import { Input, Button, Space, Row, Form } from 'antd';
import { Cron } from 'react-js-cron';
import './CronInput.less';
import 'react-js-cron/dist/styles.css';

const MODE_SIMPLE = 'simple';
const MODE_ADVANCED = 'advanced';

export type CronInputProps = {
    value?: string;
    onChange?: Function;
};

function CronInput({ value, onChange, ...rest }: CronInputProps) {
    const [mode, setMode] = useState(MODE_SIMPLE);
    const [cronValue, setCronValue] = useState(value);
    const handleChange = (event) => {
        setCronValue(event.target.value);
        onChange?.(event.target.value);
    };
    const handleCronValueChange = (value) => {
        setCronValue(value);
        onChange?.(value);
    };
    return (
        <>
            <Space style={{ paddingBottom: '10px' }}>
                <Button.Group>
                    <Button
                        type={mode === MODE_SIMPLE ? 'primary' : ''}
                        onClick={() => setMode(MODE_SIMPLE)}
                    >
                        Simple
                    </Button>
                    <Button
                        type={mode === MODE_ADVANCED ? 'primary' : ''}
                        onClick={() => setMode(MODE_ADVANCED)}
                    >
                        Advanced
                    </Button>
                </Button.Group>
            </Space>
            <Row>
                {mode === MODE_SIMPLE ? (
                    <Cron
                        value={cronValue}
                        setValue={handleCronValueChange}
                        className="cron-schedule"
                        {...rest}
                    />
                ) : (
                    <Input value={cronValue} onChange={handleChange} />
                )}
            </Row>
        </>
    );
}
export default CronInput;
