
import CmonRequestService, {CmonRequestServiceResponseData} from './CmonRequestService';


export interface GetInfoRequestData {
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface StatByNameRequestData {
    cluster_id?: number;
    end_datetime?: string;
    name?: string;
    request_created?: string;
    request_id?: number;
    start_datetime?: string;
    with_hosts?: boolean;
    calculate_per_sec?: boolean;
    [key: string]: any;
}

export interface GetCpuPhysicalInfoRequestData {
    cluster_id?: number;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface GetTopQueriesRequestData {
    hostname?: string;
    className?: string;
    requestCreated?: string;
    requestType?: string;
    requestData?: {[key:string]:any};
    [key: string]: any;
}

export interface GetDbGrowthRequestData {
    cluster_id?: number;
    [key: string]: any;
}

export interface PrometheusProxyRequestData {
    queries?: [];
    start?: number;
    end?: number;
    step?: number;
    returnfrom?: number;
    cluster_id?: number;
    [key: string]: any;
}

export interface Qm_topqueriesRequestData {
    cluster_id?: number;
    limit?: number;
    offset?: number;
    [key: string]: any;
}

export interface Qm_queryoutliersRequestData {
    cluster_id?: number;
    starttime?: number;
    endttime?: number;
    limit?: number;
    offset?: number;
    [key: string]: any;
}

export interface Qm_purgeRequestData {
    cluster_id?: number;
    [key: string]: any;
}

export interface Qm_killprocessRequestData {
    cluster_id?: number;
    pid?: number;
    host_port?: [];
    [key: string]: any;
}

export interface Qm_processesRequestData {
    cluster_id?: number;
    limit?: number;
    offset?: number;
    [key: string]: any;
}

export interface GettxdeadlockRequestData {
    cluster_id?: number;
    limit?: number;
    offset?: number;
    [key: string]: any;
}

export interface NodevariablesRequestData {
    cluster_id?: number;
    [key: string]: any;
}

export interface GetdbstatusRequestData {
    cluster_id?: number;
    [key: string]: any;
}


export default class CmonStatService extends CmonRequestService {
    
    public static module(): string {
        return 'stat';
    }
    
    public static async getInfo(
        data: GetInfoRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getInfo', data, opts);
    }

    public static async statByName(
        data: StatByNameRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('statByName', data, opts);
    }

    public static async getCpuPhysicalInfo(
        data: GetCpuPhysicalInfoRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getCpuPhysicalInfo', data, opts);
    }

    public static async getTopQueries(
        data: GetTopQueriesRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getTopQueries', data, opts);
    }

    public static async getDbGrowth(
        data: GetDbGrowthRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getDbGrowth', data, opts);
    }

    public static async prometheusProxy(
        data: PrometheusProxyRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('/prometheus', data, opts);
    }

    public static async qm_topqueries(
        data: Qm_topqueriesRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('qm_topqueries', data, opts);
    }

    public static async qm_queryoutliers(
        data: Qm_queryoutliersRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('qm_queryoutliers', data, opts);
    }

    public static async qm_purge(
        data: Qm_purgeRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('qm_purge', data, opts);
    }

    public static async qm_killprocess(
        data: Qm_killprocessRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('qm_killprocess', data, opts);
    }

    public static async qm_processes(
        data: Qm_processesRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('qm_processes', data, opts);
    }

    public static async gettxdeadlock(
        data: GettxdeadlockRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('gettxdeadlock', data, opts);
    }

    public static async nodevariables(
        data: NodevariablesRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('nodevariables', data, opts);
    }

    public static async getdbstatus(
        data: GetdbstatusRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getdbstatus', data, opts);
    }

}
