import React, { useEffect, useMemo, useState } from 'react';
import './ContentWithLayout.less';
import AppLayout, {
    AppMenuItem,
} from '@severalnines/bar-frontend-components/build/lib/Layout/AppLayout';
import AppErrorBoundary from '@severalnines/bar-frontend-components/build/lib/Errors/AppErrorBoundary';
import InternetConnectionBanner from '@severalnines/bar-frontend-components/build/lib/Feedback/InternetConnectionBanner';
import { Link, useLocation, matchPath } from 'react-router-dom';
import {
    ActivityIcon,
    BackupsIcon,
    ClustersIcon,
    NodesIcon,
    SettingsIcon,
    UserManagementIcon,
} from './common/icons/icons';
import useClusterList from './components/Clusters/useClusterList';
import LoadingPage from './common/LoadingPage';
import { AppState, closeActivityQuickView } from './appReducer';
import { useDispatch, useSelector } from 'react-redux';
import { AppConfig } from './AppConfig';
import { Space } from 'antd';
import OpenActivityCenterButton from './components/ActivityCenter/OpenActivityCenterButton';
import ActivityQuickView from './components/ActivityCenter/ActivityQuickView';
import Feedback from './Feedback';
import CreateServiceButton from './components/Services/CreateServiceButton';
import Breadcrumb from './common/Breadcrumb';
import { HomeOutlined, AuditOutlined } from '@ant-design/icons';
import { BreadcrumbConfig } from './common/useBreadcrumb';
import { StatusFormatType } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import ClusterStateFormat from './components/Clusters/ClusterStateFormat';
import { useDebugContext } from './common/Debug';
import LicenseStateList from './components/License/LicenseStateList';
import useCurrentUser from './components/User/useCurrentUser';
import UserAclExecuteClusters from './components/User/UserAclExecuteClusters';
import CcCluster from './services/models/CcCluster';
import CurrentUserMenu from './components/User/CurrentUserMenu';
import useActiveKeys from '@severalnines/bar-frontend-components/build/lib/hooks/useActiveKeys';
import { NotificationProvider } from './components/Notifications/NotificationProvider';
import classNames from 'classnames';
import usePingHome from './common/hooks/usePingHome';
import { requestCMONVersion } from './components/Auth/Registration';
import useBackgroundPolling from './useBackgroundPolling';

export default ContentWithLayout;

export type ContentWithLayoutProps = {
    children: any;
    transparentContent?: boolean;
    contentSize?: string[];
    breadcrumb?: BreadcrumbConfig;
    layoutTopExtra?: React.ReactNode;
    layout?: 'default' | null;
};

function ContentWithLayout({
    children,
    transparentContent,
    contentSize,
    breadcrumb,
    layoutTopExtra,
    layout = 'default',
}: ContentWithLayoutProps) {
    const { log } = useDebugContext();
    const location = useLocation();
    const [activityQuickViewOpened]: [
        boolean
    ] = useSelector(({ activityQuickViewOpened }: AppState) => [
        activityQuickViewOpened,
    ]);
    const dispatch = useDispatch();
    usePingHome();
    const { start: startBackgroundPolling } = useBackgroundPolling();

    const {
        list: clusterList,
        refresh: refreshClusterList,
        loaded: loadedClusters,
    } = useClusterList({
        name: 'cluster-list-from-Layout',
    });

    const {
        canManageUsers,
        canManageConfiguration,
        canManageLdapSettings,
        record: loggedInUser,
    } = useCurrentUser({ throwError: true });

    useEffect(() => {
        if (loggedInUser) {
            (async () => {
                await refreshClusterList({ autoRefresh: 30000 });
            })();
        }
    }, [loggedInUser]);
    useEffect(() => {
        if (loadedClusters) {
            (async () => {
                await startBackgroundPolling();
            })();
        }
    }, [loadedClusters]);

    const menuItems: AppMenuItem[] = useMemo(() => {
        return [
            {
                key: '/dashboard',
                icon: <HomeOutlined />,
                label: (
                    <Link to="/dashboard">
                        <span>Home</span>
                    </Link>
                ),
            },
            {
                key: '/clusters',
                label: (
                    <Link
                        to="/clusters"
                        onClick={(e) => {
                            dispatch(closeActivityQuickView());
                            e.stopPropagation();
                        }}
                    >
                        <ClustersIcon />
                        <span>Clusters</span>
                    </Link>
                ),
                selectable: true,
                children: clusterList?.map((cluster: CcCluster) => {
                    // check if path comes from another cluster page
                    // so we keep the dashboard selected if it is available
                    const matchClusterDashboardPage = matchPath(
                        '/clusters/:id/dashboard',
                        location.pathname
                    );
                    const matchClusterPage = matchPath(
                        '/clusters/:id/*',
                        location.pathname
                    );
                    return {
                        key: `/clusters/${cluster.clusterId}`,
                        label: (
                            <Link
                                to={`/clusters/${cluster.clusterId}/${
                                    matchClusterPage
                                        ? matchClusterPage?.params['*']
                                        : 'nodes'
                                }${
                                    matchClusterDashboardPage
                                        ? location.search
                                        : ''
                                }`}
                            >
                                <ClusterStateFormat
                                    cluster={cluster}
                                    type={StatusFormatType.dot}
                                    text={''}
                                />
                                {cluster.clusterName}
                            </Link>
                        ),
                    };
                }),
            },
            {
                key: '/nodes',
                icon: <NodesIcon />,
                label: (
                    <Link to="/nodes">
                        <span>Nodes</span>
                    </Link>
                ),
            },
            {
                key: '/backup',
                icon: <BackupsIcon />,
                label: (
                    <Link to="/backup/list">
                        <span>Backups</span>
                    </Link>
                ),
            },
            {
                key: '/activity-center',
                icon: <ActivityIcon />,
                label: (
                    <Link to="/activity-center">
                        <span>Activity center</span>
                    </Link>
                ),
            },
            ...(loggedInUser && canManageConfiguration()
                ? [
                      {
                          key: '/operational-reports',
                          icon: <AuditOutlined />,
                          label: (
                              <Link to="/operational-reports/reports">
                                  <span>Operational reports</span>
                              </Link>
                          ),
                      },
                  ]
                : []),
            ...(loggedInUser && (canManageUsers() || canManageLdapSettings())
                ? [
                      {
                          key: '/user-management',
                          icon: <UserManagementIcon />,
                          label: (
                              <Link
                                  to={`/user-management/${
                                      canManageUsers() ? 'users' : 'ldap'
                                  }`}
                              >
                                  <span>User management</span>
                              </Link>
                          ),
                      },
                  ]
                : []),
            ...(loggedInUser && canManageConfiguration()
                ? [
                      {
                          key: '/settings',
                          icon: <SettingsIcon />,
                          label: (
                              <Link to="/settings/profile">
                                  <span>Settings</span>
                              </Link>
                          ),
                      },
                  ]
                : []),
        ];
    }, [
        location.pathname,
        clusterList,
        canManageUsers,
        canManageConfiguration,
        loggedInUser,
        canManageLdapSettings,
    ]);

    const activeKeys = useActiveKeys(location.pathname, location, menuItems!);

    const handleLayoutClick = () => {
        if (activityQuickViewOpened) {
            // we close activity quick view when user clicks any place in the layout
            dispatch(closeActivityQuickView());
        }
    };

    const headerExtra = useMemo(
        () =>
            loggedInUser ? (
                <Space className="ContentWithLayout_header-extra-container">
                    <Space>
                        <LicenseStateList size="small" />
                        <div>
                            {breadcrumb ? (
                                <Breadcrumb config={breadcrumb} />
                            ) : null}
                        </div>
                    </Space>

                    <Space>
                        {/*<GlobalFilter*/}
                        {/*    disabled={[*/}
                        {/*        '/stats/controllers',*/}
                        {/*        '/controllers',*/}
                        {/*    ].includes(location.pathname)}*/}
                        {/*/>*/}
                        <UserAclExecuteClusters>
                            <CreateServiceButton />
                        </UserAclExecuteClusters>
                        <OpenActivityCenterButton
                            type={activityQuickViewOpened ? 'link' : 'text'}
                        >
                            <ActivityIcon />
                        </OpenActivityCenterButton>
                        <CurrentUserMenu user={loggedInUser} />
                    </Space>
                </Space>
            ) : null,
        [loggedInUser, activityQuickViewOpened, breadcrumb]
    );
    const [cmonVersion, setCmonVersion] = useState<string>();
    useEffect(() => {
        (async () => {
            const version = await requestCMONVersion();
            setCmonVersion(version);
        })();
    }, []);

    return loggedInUser && loadedClusters ? (
        <AppErrorBoundary>
            <NotificationProvider>
                <div
                    className={classNames(
                        'ContentWithLayout',
                        `ContentWithLayout--layout-${layout}`
                    )}
                >
                    <AppLayout
                        menuItems={menuItems}
                        menuProps={{
                            theme: 'light',
                            style: { borderRight: '0' },
                            inlineIndent: 20,
                        }}
                        contentTopExtra={layoutTopExtra}
                        headerExtra={headerExtra}
                        headerExtraStyle={{
                            width: '100%',
                        }}
                        headerBottomExtra={
                            <div>
                                {/*<Affix offsetTop={0}>*/}
                                {loggedInUser ? (
                                    <ActivityQuickView
                                        visible={activityQuickViewOpened}
                                    />
                                ) : null}
                                {/*</Affix>*/}
                                <InternetConnectionBanner />
                            </div>
                        }
                        headerType="fixed"
                        logoProps={{
                            src: `/clustercontrol.svg`,
                            style: {
                                width: '180px',
                            },
                        }}
                        logoCollapsedProps={{
                            src: `/clustercontrol-small.svg`,
                            style: { width: '30' },
                        }}
                        logoResponsiveProps={{
                            src: `/clustercontrol.svg`,
                            style: {
                                marginTop: '10px',
                                marginLeft: '8px',
                                width: '180px',
                            },
                        }}
                        siderProps={{
                            theme: 'light',
                            className: 'ContentWithLayout_sider',
                            activeKeys: activeKeys,
                        }}
                        transparentContent={transparentContent}
                        contentSize={contentSize}
                        footerProps={{
                            extra: (
                                <span>
                                    <br />
                                    Controller:{cmonVersion}. Build
                                    {AppConfig.getProductVersion()}
                                </span>
                            ),
                            className: 'ContentWithLayout_footer',
                        }}
                        headerSize={48}
                        onClick={handleLayoutClick}
                    >
                        {children}
                        <Feedback user={loggedInUser} />
                    </AppLayout>
                </div>
            </NotificationProvider>
        </AppErrorBoundary>
    ) : (
        <LoadingPage />
    );
}
