import React from 'react';
import ClusterTopologySmall from '../components/Clusters/ClusterTopologySmall';
import CcCluster from '../services/models/CcCluster';
import SpaceDescriptions from '../common/Layout/SpaceDescriptions';
import AppDivider from '../common/AppDivider';
import galera from './dataMocks/clusters/galera';
import mongodb1 from './dataMocks/clusters/mongodb1';
import mongodb2 from './dataMocks/clusters/mongodb2';
import mysqlReplication from './dataMocks/clusters/mysqlReplication';
import mysqlReplicationIntermediate from './dataMocks/clusters/mysqlReplicationIntermediate';
import mysqlReplicationIntermediate1 from './dataMocks/clusters/mysqlReplicationIntermediate1';
import postgresStreamingReplication from './dataMocks/clusters/postgresStreamingReplication';
import redis6dev from './dataMocks/clusters/redis6SingleNodeShutdown';
import redis6sentinel from './dataMocks/clusters/redis6Sentinel3Nodes';
import postgresSingleNode from './dataMocks/clusters/postgresSingleNode';
import postgresWithLb from './dataMocks/clusters/postgresWithLb';
import mssqlSingle from './dataMocks/clusters/mssqlSingle';
import mssqlReplication from './dataMocks/clusters/mssqlReplication';
import ClusterFormat from '../components/Clusters/ClusterFormat';
import merge from 'deepmerge';

const allClustersObjects = [
    mssqlSingle,
    mssqlReplication,
    galera,
    mongodb1,
    mongodb2,
    mysqlReplication,
    {
        ...mysqlReplication,
        cluster_name: 'MySQL Replication (with problems)',
        cluster_id: 500,
        hosts: mysqlReplication.hosts.map((h, idx) => {
            let newHost = { ...h };
            if (
                newHost.class_name === 'CmonMySqlHost' &&
                newHost.role === 'slave'
            ) {
                newHost = merge(newHost as Object, {
                    replication_slave: {
                        slave_io_running: 'No',
                    },
                    hoststatus:
                        idx % 2 === 0 ? 'CmonHostOffLine' : 'CmonHostShutDown',
                }) as any;
            }
            return newHost;
        }),
    },
    mysqlReplicationIntermediate1,
    mysqlReplicationIntermediate,
    postgresStreamingReplication,
    postgresWithLb,
    {
        ...postgresWithLb,
        cluster_name: 'Postgres (with maintenance mode)',
        cluster_id: 600,
        hosts: postgresWithLb.hosts.map((h) => {
            const newHost = { ...h };
            if (newHost.class_name === 'CmonPostgreSqlHost') {
                newHost.maintenance_mode_active = true;
            }
            return newHost;
        }),
    },
    {
        ...postgresWithLb,
        cluster_name: 'Postgres (with problems)',
        cluster_id: 601,
        hosts: postgresWithLb.hosts.map((h, idx) => {
            let newHost = { ...h };
            if (newHost.class_name === 'CmonPostgreSqlHost') {
                newHost = merge(newHost as Object, {
                    replication_state: 'other',
                    hoststatus:
                        idx % 2 === 0 ? 'CmonHostOffLine' : 'CmonHostShutDown',
                }) as any;
            }
            return newHost;
        }),
    },
    postgresSingleNode,

    {
        ...postgresWithLb,
        cluster_name: 'My Postgres Master Cluster',
        cluster_id: 1000,
    },
    {
        ...postgresSingleNode,
        cluster_name: 'My Postgres Slave Cluster',
        cluster_id: 1001,
    },
    {
        ...postgresSingleNode,
        cluster_name: 'My Postgres Slave Cluster 2 (bidirectional)',
        cluster_id: 1002,
    },
    redis6dev,
    redis6sentinel,
    {
        ...redis6sentinel,
        cluster_name: 'Redis (with problems)',
        cluster_id: 500,
        hosts: redis6sentinel.hosts.map((h, idx) => {
            let newHost = { ...h };
            if (
                newHost.class_name === 'CmonRedisHost' &&
                newHost.role === 'slave'
            ) {
                newHost = merge(newHost as Object, {
                    replication_slave: {
                        status: 'down',
                    },
                    hoststatus:
                        idx % 2 === 0 ? 'CmonHostOffLine' : 'CmonHostShutDown',
                }) as any;
            }
            return newHost;
        }),
    },
].map((c) => new CcCluster(c as any));

// mocking cluster2cluster replication
const allClusters = allClustersObjects.map((c: CcCluster) => {
    if (c.clusterId === 1000) {
        c.setReplicationSecondaries([
            allClustersObjects.find(
                (cluster) => cluster.clusterId === 1001
            ) as CcCluster,
            allClustersObjects.find(
                (cluster) => cluster.clusterId === 1002
            ) as CcCluster,
        ]);
        c.setReplicationPrimaries([
            allClustersObjects.find(
                (cluster) => cluster.clusterId === 1002
            ) as CcCluster,
        ]);
    }
    if (c.clusterId === 1001) {
        c.setReplicationPrimaries([
            allClustersObjects.find(
                (cluster) => cluster.clusterId === 1000
            ) as CcCluster,
        ]);
    }
    if (c.clusterId === 1002) {
        c.setReplicationPrimaries([
            allClustersObjects.find(
                (cluster) => cluster.clusterId === 1000
            ) as CcCluster,
        ]);
    }

    return c;
});

export default DebugClusterTopologySmall;

export type DebugClusterTopologySmallProps = {};

function DebugClusterTopologySmall({}: DebugClusterTopologySmallProps) {
    return (
        <div className="DebugClusterTopologySmall">
            {allClusters.map((c) => {
                return (
                    <div key={c.clusterName}>
                        <SpaceDescriptions
                            title={<ClusterFormat cluster={c} />}
                        >
                            <ClusterTopologySmall cluster={c} />
                        </SpaceDescriptions>
                        <AppDivider />
                    </div>
                );
            })}
        </div>
    );
}
