import React from 'react';
import ActionsMenu, {
    ActionsMenuProps,
} from '../../common/Navigation/ActionsMenu';
import CcAlarm from '../../services/models/CcAlarm';
import AlarmIgnoreButton from './AlarmIgnoreButton';
import AlarmDetailsButton from './AlarmDetailsButton';
import CcCluster from '../../services/models/CcCluster';
import useCurrentUser from '../User/useCurrentUser';
import { AudioMutedOutlined, FileTextOutlined } from '@ant-design/icons';

export default AlarmActionsMenu;

export type AlarmActionsMenuProps = ActionsMenuProps & {
    cluster?: CcCluster;
    alarm: CcAlarm;
};

function AlarmActionsMenu({
    cluster,
    alarm,
    onActionPerformed,
    ...rest
}: AlarmActionsMenuProps) {
    const { canManageCluster, canExecuteClusters } = useCurrentUser();
    const handleAlarmIgnoreSuccess = () => {
        if (onActionPerformed) {
            onActionPerformed();
        }
    };
    return (
        <ActionsMenu
            items={[
                {
                    key: 'details',
                    icon: <FileTextOutlined />,
                    label: <AlarmDetailsButton alarm={alarm} />,
                },
                ...((!cluster?.clusterId && canExecuteClusters()) ||
                (cluster?.clusterId &&
                    canManageCluster(cluster?.clusterId as number))
                    ? [
                          {
                              key: 'ignore',
                              icon: <AudioMutedOutlined />,
                              waitForConfirm: true,
                              critical: true,
                              label: (
                                  <AlarmIgnoreButton
                                      alarm={alarm}
                                      onSuccess={handleAlarmIgnoreSuccess}
                                  />
                              ),
                          } as any,
                      ]
                    : []),
            ]}
            {...rest}
        />
    );
}
