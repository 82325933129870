import { createContext, ProviderProps, useContext } from 'react';
import {
    Button,
    Modal as AntModal,
    notification,
    notification as AntNotification,
} from 'antd';
import ActivityCenterJobsLink from '../ActivityCenter/ActivityCenterJobsLink';

import {
    notifyError,
    notifyOperationSuccess,
    NotifyOperationSuccessProps,
    NotifyType,
    NotificationApi,
    NotifyErrorProps,
    ModalApi,
} from './uiNotification';
import SpaceWide from '../../common/SpaceWide';
import CcJob from '../../services/models/CcJob';

export type NotifyJobCreationSuccessProps = Omit<
    NotifyOperationSuccessProps,
    'renderFooter'
> & {
    job?: CcJob;
    renderFooter?: (modalOrToast?: any, job?: CcJob) => React.ReactNode;
};

export type NotifyJobCreationErrorProps = NotifyErrorProps;

export type NotificationContextProps = {
    notifyJobCreationSuccess: (
        props?: NotifyJobCreationSuccessProps
    ) => NotificationApi;
    notifyJobCreationError: (
        props?: NotifyJobCreationErrorProps
    ) => NotificationApi;
};

export const NotificationContext = createContext<NotificationContextProps>(
    {} as NotificationContextProps
);

export type NotificationProviderProps = ProviderProps<
    NotificationContextProps
> & {};

export const NotificationProvider = ({
    children,
}: NotificationProviderProps) => {
    const [toastApi, toastContextHolder] = AntNotification.useNotification();
    const [modalApi, modalContextHolder] = AntModal.useModal();
    const notifyJobCreationError = ({
        type = NotifyType.TOAST,
        title,
        content,
        ...rest
    }: NotifyJobCreationSuccessProps = {}) => {
        return notifyError({
            ...(type === NotifyType.MODAL
                ? { modalApi: modalApi }
                : { toastApi: toastApi }),
            title: title || 'There was an error while creating the job!',
            content: content || 'Error when trying to create the job',
            type,
            ...rest,
        });
    };
    const notifyJobCreationSuccess = ({
        type = NotifyType.TOAST,
        job,
        onOk,
        renderFooter: renderFooterFn,
        ...rest
    }: NotifyJobCreationSuccessProps = {}) => {
        const key = job?.getKey();
        return notifyOperationSuccess({
            title: 'Job created successfully',
            key,
            content:
                type === NotifyType.TOAST ? (
                    <span>This may take some time, please wait.</span>
                ) : (
                    'Job is in progress, you can follow status in activity list.'
                ),
            ...(type === NotifyType.MODAL
                ? {
                      modalApi: modalApi,
                      renderFooter: (modal: any) => (
                          <SpaceWide justify="center">
                              {renderFooterFn?.(job)}
                              <Button
                                  type="default"
                                  onClick={() => {
                                      modal.destroy();
                                      onOk?.();
                                  }}
                              >
                                  Close
                              </Button>
                              <Button type="primary">
                                  <ActivityCenterJobsLink />
                              </Button>
                          </SpaceWide>
                      ),
                  }
                : {
                      toastApi: toastApi,
                      renderFooter: () => (
                          <SpaceWide justify="right">
                              {renderFooterFn?.(job)}
                              <ActivityCenterJobsLink
                                  onClick={() => {
                                      notification?.close?.(key as string);
                                  }}
                              />
                          </SpaceWide>
                      ),
                  }),
            type,
            job,
            ...rest,
        });
    };
    const notificationContext = {
        notifyJobCreationError,
        notifyJobCreationSuccess,
    };

    return (
        <NotificationContext.Provider value={notificationContext}>
            {toastContextHolder}
            {modalContextHolder}
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotificationContext = (): NotificationContextProps =>
    useContext(NotificationContext);
